<template>
  <div>
    <el-row class="footer">
      <el-col :span="24"></el-col>
    </el-row>
  </div>
</template>
<script>
export default {

}
</script>

<style>
.footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}
</style>
